import axios from "axios";
const token = localStorage.getItem("auth_token")
export const useJewelerApi =  () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers:{
      Authorization: `Bearer ${token}` 
    }
  });
};
export const useJewelerApiWithoutToken =  () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
};
export const useJewelerApiFile =  () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers:{
      "Content-Type": "multipart/form-data"
    }
  });
};

